import { fixedValueDigit } from "../../../utils/helper";

export const winPlaceColumnHandling = (
  detailData,
  horseNum,
  winPlaceOdds,
  winOddsAll,
  qwOddsAll,
  confidentIndex,
  ciAll,
  expectedWinOddsAll,
  qwLatest
) => {
  try {
    // handle win place odds
    const odds = winPlaceOdds?.[horseNum];
    if (odds) {
      for (const interval of [
        "thirty",
        "ten",
        "six",
        "three",
        "zero",
        "latest",
      ]) {
        const o = odds[interval];
        let w;
        if (Array.isArray(o || [])) {
          w = o ? odds?.[interval]?.[0] : null;
        } else {
          w = o ? odds?.[interval]?.win : null;
        }
        detailData.winPlaceOdds[interval] = w
          ? w?.toFixed(w >= 10 ? 0 : 1)
          : -1;
      }

      const threeOdds = odds?.["three"]?.win;
      if (threeOdds != null) {
        for (const interval of ["two", "one", "zero", "latest"]) {
          const o = odds[interval]?.win;
          if (o == null) {
            continue;
          }
          const result = (threeOdds / o - 1) * 100;
          detailData.winInvestmentDiff[interval] =
            Math.abs(result) > 0
              ? Number(result.toFixed(1))
              : Math.abs(result) > 10
              ? Number(result.toFixed(0))
              : 0;
        }
      }
    }
    // handle win place ci
    if (confidentIndex?.[horseNum]) {
      detailData.confidentIndex = fixedValueDigit(confidentIndex[horseNum], 2);
    }
    // handle win line chart data
    if (winOddsAll) {
      const winOdds = winOddsAll?.[horseNum]
        ? winOddsAll[horseNum]?.slice(40)
        : [];
      winOdds.forEach((odds, index) => {
        if (detailData.winGraphData[index]) {
          detailData.winGraphData[index]["win"] = odds;
        }
      });
    }
    if (qwOddsAll) {
      const qwOdds = qwOddsAll?.[horseNum]
        ? qwOddsAll[horseNum]?.slice(10)
        : [];
      qwOdds.forEach((odds, index) => {
        if (detailData.winGraphData[index]) {
          detailData.winGraphData[index]["qw"] = odds;
        }
      });
    }
    if (ciAll) {
      const ci = ciAll?.[horseNum] ? ciAll[horseNum]?.slice(10) : [];
      ci.forEach((ci, index) => {
        if (detailData.winGraphData[index]) {
          detailData.winGraphData[index]["ci"] = ci;
        }
      });
    }

    if (expectedWinOddsAll?.[horseNum]) {
      const expectedWinOdds = Array.isArray(expectedWinOddsAll[horseNum])
        ? expectedWinOddsAll[horseNum]?.slice(
            expectedWinOddsAll[horseNum].length - 21
          )
        : [];
      expectedWinOdds.forEach((odds, index) => {
        if (detailData.winGraphData[index]) {
          detailData.winGraphData[index]["expectedWinOdds"] = odds;
        }
      });
    }

    const latestWin = detailData.winPlaceOdds["latest"];
    const latestQWin = qwLatest?.[horseNum];
    latestWin &&
      latestQWin &&
      (detailData.winQWinRatio = latestQWin / latestWin);
  } catch (e) {
    console.error(e);
  }
};

export const forecastColumnHandling = (
  detailData,
  horseNum,
  forecastOdds,
  forecastCi,
  forecastHorseNum
) => {
  try {
    const forecastMapping = ["lowest", "secondLowest", "thirdLowest"];
    // map forecast odds of a horse to the horse data
    if (forecastOdds?.horses) {
      for (const field of forecastMapping) {
        detailData.forecastOdds[field] =
          forecastOdds.horses?.[field]?.[horseNum] != null
            ? forecastOdds.horses[field][horseNum]
            : detailData.forecastOdds[field];
      }
    }
    // map forecast ci of a horse to the horse data
    for (const [field, apiField] of Object.entries(forecastHorseNum)) {
      if (
        !forecastCi?.[apiField]?.[horseNum] ||
        forecastCi[apiField][horseNum] <= 0
      ) {
        continue;
      }
      detailData.forecastCi[field] = forecastCi[apiField][horseNum].toFixed(1);
    }
  } catch (e) {
    console.error(e);
  }
};

export const quinellaColumnHandling = (
  detailData,
  horseNum,
  quinellaOdds,
  quinellaCi,
  forecastHorseNum
) => {
  try {
    // map quinella odds of a horse to the horse data
    if (quinellaOdds) {
      for (const [field, apiField] of Object.entries(forecastHorseNum)) {
        detailData.quinellaOdds[field] =
          quinellaOdds?.[apiField]?.[horseNum] != null
            ? quinellaOdds[horseNum][apiField]
            : detailData.quinellaOdds[field];
      }
    }
    // map quinella ci of a horse to the horse data
    for (const [field, apiField] of Object.entries(forecastHorseNum)) {
      if (
        !quinellaCi?.[apiField]?.[horseNum] ||
        quinellaCi[apiField][horseNum] <= 0
      ) {
        continue;
      }
      detailData.quinellaCi[field] = quinellaCi[apiField][horseNum].toFixed(1);
    }
  } catch (e) {
    console.error(e);
  }
};

export const derivativeOddsColumnHandling = (
  detailData,
  horseNum,
  winToWin,
  quinellaToWin
) => {
  try {
    // direct mapping without stating api field
    winToWin?.[horseNum] != null &&
      (detailData.derivativeOdds.winToWin = winToWin[horseNum]);

    quinellaToWin?.[horseNum] != null &&
      (detailData.derivativeOdds.quinellaToWin = quinellaToWin[horseNum]);
  } catch (e) {
    console.error(e);
  }
};

export const fQHandlingV2 = (
  signalData,
  additionalData,
  detailData,
  fQSignal,
  forecastOdds,
  quinellaOdds,
  forecastOddsNorm,
  quinellaOddsNorm,
  zero
) => {
  try {
    const totalNumOfHorses = additionalData.numOfHorseCurrent;
    let hottestList = Object.values(detailData).map(
      ({ horseNum, winPlaceOdds }) => {
        return { horseNum, winPlaceOdds: winPlaceOdds.latest };
      }
    );
    hottestList.sort((a, b) => {
      if (detailData[a.horseNum].scratched) return 1;
      if (detailData[b.horseNum].scratched) return -1;
      return Number(a.winPlaceOdds) - Number(b.winPlaceOdds);
    });
    hottestList = hottestList.map((horse) => horse.horseNum);
    additionalData.hottestList = hottestList;

    for (let i = 1; i <= totalNumOfHorses; i++) {
      forecastOdds?.[i] &&
        (detailData[String(i)].forecastAbs = forecastOdds?.[i]);
      forecastOddsNorm?.[i] &&
        (detailData[String(i)].forecastNormAbs = forecastOddsNorm?.[i]);
      quinellaOdds?.[i] &&
        (detailData[String(i)].quinellaAbs = quinellaOdds?.[i]);
      quinellaOddsNorm?.[i] &&
        (detailData[String(i)].quinellaNormAbs = quinellaOddsNorm?.[i]);
    }

    if (fQSignal == null) {
      return;
    }
    const fqScoreSum = {};

    // forecast/quinella signal handling, map for scatter plot
    const fQSignalList = [];

    const mapping = (data, firstHorseNum, secondHorseNum) => {
      fQSignalList.push({
        firstHorseNum: firstHorseNum,
        sceondHorseNum: secondHorseNum,
        firstHorseNumIndex: hottestList.indexOf(firstHorseNum) + 1,
        sceondHorseNumIndex: hottestList.indexOf(secondHorseNum) + 1,
        index: data,
      });
      if (!(firstHorseNum in fqScoreSum)) {
        fqScoreSum[firstHorseNum] = 0;
      }
      fqScoreSum[firstHorseNum] +=
        data === 1 ? 3 : data === 2 ? 2 : data === 3 ? 1 : data === 4 ? -1 : 0;
    };

    // loop each cominbation and check if it has a signal
    for (let i = 1; i <= totalNumOfHorses; i++) {
      for (let j = 1; j <= totalNumOfHorses; j++) {
        if (i === j || !fQSignal?.[i]?.[j]) {
          continue;
        }
        mapping(fQSignal[i][j], i, j);
      }
    }
    if (fQSignalList.length === 0) {
      return;
    }
    if (zero) {
      // deprecated
    } else {
      signalData.fQSignal = fQSignalList;
      signalData.fqScoreSum = fqScoreSum;
    }
  } catch (e) {
    console.error(e);
  }
};

export const doubleSignalHandling = (
  additionalData,
  signalData,
  detailData,
  doubleCI,
  weightedDoubleCI
) => {
  try {
    const { hottestList, numOfHorseCurrent, numOfHorseNext } = additionalData;

    // double signal handling, map for scatter plot
    if (weightedDoubleCI) {
      let secondHottestList = Object.entries(weightedDoubleCI?.nextRace || {})
        .map(([horseNum, value]) => {
          return { horseNum: Number(horseNum), winPlaceOdds: value.winOdds };
        })
        .filter((horse) => horse.winPlaceOdds > 0);

      secondHottestList.sort((a, b) => {
        if (a.scratched) return 1;
        if (b.scratched) return -1;
        return Number(a.winPlaceOdds) - Number(b.winPlaceOdds);
      });
      secondHottestList = secondHottestList.map((horse) => horse.horseNum);

      additionalData.secondHottestList = secondHottestList;

      signalData.weightedDoubleCI = weightedDoubleCI?.nextRace;
    }

    if (doubleCI && numOfHorseNext) {
      const doubleList = [];
      const mapping = (data, firstHorseNum, secondHorseNum) => {
        doubleList.push({
          firstHorseNum: firstHorseNum,
          sceondHorseNum: secondHorseNum,
          firstHorseNumIndex: hottestList.indexOf(firstHorseNum) + 1,
          sceondHorseNumIndex:
            additionalData.secondHottestList.indexOf(secondHorseNum) + 1,
          index: data,
        });
      };
      for (let i = 1; i <= numOfHorseCurrent; i++) {
        if (detailData?.[i]?.scratched) {
          continue;
        }

        for (let j = 1; j <= numOfHorseNext; j++) {
          mapping(doubleCI?.[i]?.[j], i, j);
        }
      }

      if (doubleList.length === 0) {
        return;
      }
      signalData.doubleSignal = doubleList;
    }
  } catch (e) {
    console.error(e);
  }
};

export const winInvesmentSumHandling = (additionalData, winInvestmentSum) => {
  if (!winInvestmentSum || !Array.isArray(winInvestmentSum)) {
    return;
  }
  try {
    additionalData.winInvestmentSum =
      winInvestmentSum?.map((entry, minute) => {
        return {
          minute: minute - 30,
          value: (entry * 100).toFixed(2),
        };
      }) || [];
  } catch (e) {
    console.error(e);
  }
};

export const winInvesmentSumTop6Handling = (
  additionalData,
  winInvestmentSumTop6
) => {
  if (!winInvestmentSumTop6 || !Array.isArray(winInvestmentSumTop6)) {
    return;
  }
  try {
    additionalData.winInvestmentSumTop6 =
      winInvestmentSumTop6?.map((entry, minute) => {
        return {
          minute: minute - 30,
          value: (entry * 100).toFixed(2),
        };
      }) || [];
  } catch (e) {
    console.error(e);
  }
};

export const expectedWinOddsHandling = (
  detailData,
  horseNum,
  expectedWinOdds
) => {
  if (!expectedWinOdds || !expectedWinOdds?.[horseNum]) {
    return;
  }
  try {
    detailData.expectedWinOdds = expectedWinOdds?.[horseNum];
  } catch (e) {
    console.error(e);
  }
};
