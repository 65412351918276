import { useSelector } from "react-redux";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import {
  getAxisStroke,
  getGridStroke,
} from "../../../services/utils/chartHelper";
import { getZeroMinute } from "../standard/graphs/WinChart";
import { useState } from "react";

const WinChart = ({ data, range, showRange }) => {
  // line chart display for win odds in quinella card
  const theme = useSelector((state) => state.general.theme);
  const { raceTime, updateTsRaw } = useSelector((state) => state.forecast);

  const { hideCILine, hidePoolRatioLine, hideWinLine, hideQWLine } =
    useSelector((state) => state.drawer.standardCardDrawer?.hideLine) || {
      hideCILine: false,
      hidePoolRatioLine: false,
      hideWinLine: false,
      hideQWLine: false,
    };

  const zeroMinute = getZeroMinute(raceTime, updateTsRaw);
  const [strokeWidth, setStrokeWidth] = useState(1.5);

  return (
    <ResponsiveContainer width="80%" height="100%">
      <LineChart
        data={data}
        margin={{ top: 2, right: 0, left: 5, bottom: 6 }}
        onMouseEnter={() => setStrokeWidth(3)}
        onMouseLeave={() => setStrokeWidth(1.5)}
      >
        <CartesianGrid stroke={getGridStroke(theme)} />
        <XAxis
          height={8}
          dataKey="minute"
          domain={range}
          ticks={showRange}
          tick={{ fontSize: "8px" }}
          stroke={getAxisStroke(theme)}
        />
        <YAxis
          width={10}
          domain={[
            (dataMin) =>
              dataMin !== Infinity
                ? dataMin <= 3.5
                  ? 0
                  : dataMin <= 8
                  ? 2
                  : dataMin <= 17
                  ? 7
                  : 16
                : null,
            (dataMax) => {
              if (dataMax === -Infinity) {
                return null;
              }
              return dataMax > 17
                ? dataMax * 1.1 >= 99
                  ? dataMax
                  : Math.ceil(dataMax * 1.1)
                : dataMax <= 3.5
                ? 4
                : dataMax <= 8
                ? 9
                : 18;
            },
          ]}
          tick={{ fontSize: "8px", dx: 5, dy: -3.5 }}
          yAxisId="1"
          interval="preserveStartEnd"
          allowDecimals={false}
          allowDataOverflow={true}
          stroke={getAxisStroke(theme)}
        />
        {!hidePoolRatioLine && (
          <Line
            type="monotone"
            yAxisId="1"
            dataKey="expectedWinOdds"
            dot={false}
            stroke="rgb(255, 0, 255)"
            strokeWidth={1.5}
          />
        )}
        {!hideWinLine && (
          <Line
            type="monotone"
            yAxisId="1"
            dataKey="win"
            dot={false}
            stroke="rgb(0, 0, 255)"
            strokeWidth={1.5}
          />
        )}
        {!hideQWLine && (
          <Line
            type="monotone"
            yAxisId="1"
            dataKey="qw"
            dot={false}
            stroke="rgb(255,120,0)"
            strokeWidth={strokeWidth}
          />
        )}
        {zeroMinute && zeroMinute > 0 && (
          <ReferenceLine
            x={zeroMinute * -1}
            yAxisId="1"
            stroke="rgba(0, 0, 0, 0.2)"
            strokeWidth={1}
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default WinChart;
