import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import keycloak from "../services/keycloak";
import { RaceSelector } from "../components/RaceCard/RaceSelector";
import HorseHideInfo from "../components/RaceCard/HorseHideInfo";
import LastUpdate from "../components/RaceCard/LastUpdate";
import QuinellaCard from "../components/RaceCard/quinella";
import { handleStandardCardEvent } from "../services/actions/raceAction";
import {
  removeForecastData,
  setCurveDisplay,
} from "../services/reducers/forecastSlice";
import {
  removeStandardData,
  resetSortedInfo,
  setRoadmapData,
} from "../services/reducers/standardSlice";
import { loadHorsePerformance } from "../services/actions/raceAction/standard";
import { RaceInfo } from "../components/RaceCard/RaceInfo";
import { Collapse, Radio } from "antd";
import ControlPanel from "../components/drawers/ControlPanel";
import useWebSocket from "../services/utils/useWebSocket";
import { handleForecastCardEvent } from "../services/actions/raceAction/forecast";
import { handleWinCardEvent } from "../services/actions/raceAction/win";
import { removeWinData } from "../services/reducers/winSlice";
import API_URL_DOMAIN from "../middleware/BaseURL";

export const ForecastCard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const DrawerItems = () => {
    return <div>a,b,c</div>;
  };

  const selectedRaceDate = useSelector((state) => state.race.selectedRaceDate);
  const selectedRaceNum = useSelector((state) => state.race.selectedRaceNum);
  const showReal = useSelector(
    (state) => state.forecast.additionalData.showRealCurve
  );
  const showNorm = useSelector(
    (state) => state.forecast.additionalData.showNormCurve
  );
  const isReplaying = useSelector((state) => state.race.isReplaying);
  const websocketURL = !isReplaying
    ? `wss://${API_URL_DOMAIN}/ui/v1/ws/forecast-race-card`
    : "";

  const { sendMessage } = useWebSocket({
    url: websocketURL,
    requestParam: {
      raceDate: selectedRaceDate,
      raceNum: selectedRaceNum,
      authorization: `${keycloak.token}`,
    },
    allowInitialize: !isReplaying && selectedRaceDate != null,
    onMessage: (payload) => {
      console.log("payload", payload);
      dispatch(
        handleForecastCardEvent(payload, selectedRaceDate, selectedRaceNum)
      );
    },
    websocketName: "forecast page",
  });

  // remove last race data when change the selected race date and race num
  useEffect(() => {
    dispatch(removeForecastData());
  }, [dispatch, selectedRaceDate, selectedRaceNum]);

  useEffect(() => {
    sendMessage({ race_date: selectedRaceDate, race_num: selectedRaceNum });
  }, [sendMessage, selectedRaceDate, selectedRaceNum]);

  // Add win data websocket connection
  const winWebsocketURL = !isReplaying
    ? `wss://${API_URL_DOMAIN}/ui/v1/ws/win-race-card`
    : "";

  const { sendMessage: sendWinMessage } = useWebSocket({
    url: winWebsocketURL,
    requestParam: {
      raceDate: selectedRaceDate,
      raceNum: selectedRaceNum,
      authorization: `${keycloak.token}`,
    },
    allowInitialize: !isReplaying && selectedRaceDate != null,
    onMessage: (payload) =>
      dispatch(handleWinCardEvent(payload, selectedRaceDate, selectedRaceNum)),
    websocketName: "win page for quinella",
  });

  // remove win data when change the selected race date and race num
  useEffect(() => {
    dispatch(removeWinData());
  }, [dispatch, selectedRaceDate, selectedRaceNum]);

  useEffect(() => {
    sendWinMessage({ race_date: selectedRaceDate, race_num: selectedRaceNum });
  }, [sendWinMessage, selectedRaceDate, selectedRaceNum]);

  const racecardWebsocketURL = `wss://${API_URL_DOMAIN}/ui/v1/ws/standard-race-card`;

  const { sendMessage: sendRacecardMessage } = useWebSocket({
    url: racecardWebsocketURL,
    requestParam: {
      raceDate: selectedRaceDate,
      raceNum: selectedRaceNum,
      authorization: `${keycloak.token}`,
    },
    allowInitialize: selectedRaceDate != null,
    onMessage: (payload) => {
      dispatch(
        handleStandardCardEvent(payload, selectedRaceDate, selectedRaceNum)
      );
    },
    websocketName: "standard page",
  });

  // remove last race data when change the selected race date and race num
  useEffect(() => {
    dispatch(removeStandardData());
    dispatch(resetSortedInfo());
    dispatch(loadHorsePerformance(selectedRaceDate, selectedRaceNum));
  }, [dispatch, selectedRaceDate, selectedRaceNum]);

  useEffect(() => {
    sendRacecardMessage({
      race_date: selectedRaceDate,
      race_num: selectedRaceNum,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendRacecardMessage, selectedRaceDate, selectedRaceNum]);

  return (
    <>
      <ControlPanel DrawerItems={DrawerItems} />
      <div style={{ paddingTop: "16px" }}>
        <div style={{ display: "flex" }}>
          <LastUpdate target="forecast" />
          <span style={{ fontSize: "16px", fontWeight: "bold" }}>
            Quinella Card
          </span>
        </div>
        <div style={{ display: "flex" }}>
          <RaceSelector />
          <HorseHideInfo />
          <RaceInfo store="standard" />
        </div>
        <QuinellaCard />
      </div>
    </>
  );
};
export default ForecastCard;
