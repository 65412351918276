import { CloseOutlined } from "@ant-design/icons";
import Icon from "@ant-design/icons";
import { getWeightedCIBackgroundColor } from "../double/CrossColumn";
import ForecastQuinellaChart from "./ForecastQuinellaChart";
import firstRankIcon from "../../../assets/media/img/first_rank_icon_alt.png";
import secondRankIcon from "../../../assets/media/img/second_rank_icon_alt.png";
import thirdRankIcon from "../../../assets/media/img/third_rank_icon_alt.png";
import fourthRankIcon from "../../../assets/media/img/fourth_rank_icon_alt.png";

const cellRendering = (
  horseNum,
  forecastHorseNum,
  data,
  ratio,
  fQSignal,
  quinellaOddsSignalDict
) => {
  // the same horse cannot have combination with itself
  if (String(horseNum) === forecastHorseNum) {
    return <CloseOutlined />;
  }
  const colorMapping = {
    1: "rgba(255, 0, 255, 0.5)",
    2: "rgba(0, 0, 255, 0.5)",
    3: "rgba(0, 255, 0, 0.5)",
    4: "rgba(255,165,0, 0.9)",
  };
  if (Number(horseNum) === Number(forecastHorseNum)) {
    return <></>;
  }
  return (
    <div>
      <ForecastQuinellaChart
        data={data}
        range={[-30, 0]}
        showRange={[-20, 0]}
        reverseOdds={false}
        ratio={ratio}
        height="100%"
        quinellaOddsSignalDict={quinellaOddsSignalDict}
      />
    </div>
  );
};

// Helper function to get background color based on rank
const getBackgroundColor = (rank) => {
  switch (rank) {
    case 1:
      return "rgb(255, 77, 77)";
    case 2:
      return "rgb(126, 255, 126)";
    case 3:
      return "rgb(146, 146, 255)";
    case 4:
      return "rgb(238, 255, 126)";
    default:
      return "";
  }
};

// Rank icon mapping
const rankIconMapping = {
  1: firstRankIcon,
  2: secondRankIcon,
  3: thirdRankIcon,
  4: fourthRankIcon,
};

// Helper function to get rank icon based on rank
const getRankIcon = (rank) => {
  if (rankIconMapping?.[rank] == null) {
    return null;
  }
  return (
    <Icon
      component={() => (
        <img
          src={rankIconMapping?.[rank]}
          alt={`Rank_[${rank}]`}
          style={{ height: "24px", width: "24px" }}
          key={rank}
        />
      )}
    />
  );
};

export const CrossColumn = (
  forecastData,
  additionalData,
  sortedHorseNumList,
  raceDetailData
) => {
  const columns = [];

  const fQStatistics = additionalData?.fQStatistics;
  const fQSignal = additionalData?.fQSignal;
  const quinellaOddsSignal = additionalData?.quinellaOddsSignal;
  // {
  // "1": {
  //   "0": 0,
  //   "3": 0,
  //   "10": 0,
  //   "20": 0,
  //   "35": 0,
  // },
  // }
  for (const key of sortedHorseNumList) {
    // Get the rank for this horse from raceDetailData if available
    const rank =
      raceDetailData && raceDetailData[key] ? raceDetailData[key].rank : null;

    const column = {
      align: "center",
      children: [
        {
          title: () => (
            <div
              style={{
                backgroundColor: getBackgroundColor(rank),
                padding: "4px",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {getRankIcon(rank)}
              <span style={{ marginLeft: rank ? "4px" : "0" }}>{key}</span>
            </div>
          ),
          align: "center",
          key: `cross_${key}`,
          width: "2.8%",
          render: (_) => {
            if (_?.fQStatisticsRow != null) {
              const statistics = fQStatistics?.[key];
              return <div></div>;
            }
            return cellRendering(
              _.horseNum,
              key,
              _.forecastGraphData[key],
              _.winRatio[key],
              fQSignal?.[_.horseNum]?.[key],
              quinellaOddsSignal?.[_.horseNum]?.[key]
            );
          },
        },
      ],
    };
    columns.push(column);
  }

  return columns;
};
