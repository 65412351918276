import React from "react";
import { useSelector } from "react-redux";
import { Table } from "antd";
import { CrossColumn } from "./CrossColumn";
import { SecondHorseColumn } from "./SecondHorseColumn";
import { WinOddsColumn } from "./WinOddsColumn";
import { WinTrendColumn } from "./WinTrendColumn";

import "../RaceTable.css";

const QuinellaCard = () => {
  const raceDetailData = useSelector((state) => state.standard.detailData);

  const dataSource = useSelector((state) =>
    Object.values(state.forecast.detailData)
  );

  dataSource.sort((a, b) =>
    a.winOddsLatest > 0
      ? b.winOddsLatest > 0
        ? a.winOddsLatest - b.winOddsLatest
        : -1
      : 999
  );

  // forecastCurrentData: odds for current race
  const forecastCurrentData = useSelector((state) => state.forecast.winOdds);
  if (dataSource.length > 0) {
    dataSource.push({ fQStatisticsRow: true } || {});
  }

  // additionalData: latest CIs of every horse
  const additionalData = useSelector((state) => state.forecast.additionalData);

  const columns = [
    SecondHorseColumn(raceDetailData),
    WinTrendColumn(),
    WinOddsColumn(),
    ...CrossColumn(
      forecastCurrentData,
      additionalData,
      dataSource.map((d) => d.horseNum).filter((d) => d != null),
      raceDetailData
    ),
  ];

  return (
    <div style={{ padding: 20 }}>
      <Table
        pagination={false}
        loading={dataSource.length === 0}
        dataSource={dataSource}
        columns={columns}
        rowKey={(record, index) => index}
      />
    </div>
  );
};

export default QuinellaCard;
