import * as Helper from "../../utils/helper";
import {
  generateEmptyValueGraphDataForecast,
  generateEmptyValueGraphDataWin,
} from "./common";

export const forecastProcessing = (state, data) => {
  // prepare and extract all required data
  const updateTS = new Date();
  const forecastOdds = data.payload.forecastOdds;
  const quinellaOdds = data.payload.quinellaOdds;
  const forecastOddsNorm = data.payload.forecastOddsNorm;
  const quinellaOddsNorm = data.payload.quinellaOddsNorm;
  const winOdds = data.payload.winOdds;
  const qwOddsAll = data.payload.qwOddsAll;
  const horses = data.payload.race?.horses;
  const raceTime = new Date(data.payload.race?.info?.raceTime || 0);
  const timeInterval = Math.floor((raceTime - updateTS) / 60000) * -1;

  // mapping each horse with win odds and all graph data
  for (const horseNum of Object.keys(horses || {})) {
    // if no win odds data, initialize
    if (!state.winOdds[horseNum]) {
      state.winOdds[horseNum] = generateEmptyValueGraphDataWin(-30, 0);
    }

    winOdds?.[horseNum] &&
      winOdds[horseNum].slice(30).forEach((data, index) => {
        state.winOdds[horseNum][index].pv = data;
      });
    qwOddsAll?.[horseNum] &&
      qwOddsAll[horseNum].forEach((data, index) => {
        if (state.winOdds[horseNum][index]) {
          state.winOdds[horseNum][index].qw = data;
        }
      });
  }
  for (const horseNum of Object.keys(horses || {})) {
    forecastHandling(
      state,
      horses,
      horseNum,
      state.winOdds,
      forecastOdds,
      quinellaOdds,
      forecastOddsNorm,
      quinellaOddsNorm
    );
  }
  state.updateTS = Helper.currentTimeIn24HoursFormat();

  // store if data already exist and no need for further fetching
  state.additionalData.fetchWin =
    !(timeInterval > 10 && winOdds) && state.additionalData.fetchWin;
  state.additionalData.fetchForecast =
    !(timeInterval > 10 && forecastOdds) && state.additionalData.fetchForecast;
  state.additionalData.fetchQuinella =
    !(timeInterval > 10 && quinellaOdds) && state.additionalData.fetchQuinella;
  state.additionalData.fetchForecastNorm =
    !(timeInterval > 10 && forecastOddsNorm) &&
    state.additionalData.fetchForecast;
  state.additionalData.fetchQuinellaNorm =
    !(timeInterval > 10 && quinellaOddsNorm) &&
    state.additionalData.fetchQuinella;
};

const forecastHandling = (
  state,
  horses,
  horseNum,
  winOdds,
  forecastOdds,
  quinellaOdds,
  forecastOddsNorm,
  quinellaOddsNorm
) => {
  // initialize, if no that horse
  if (state.detailData[horseNum] == null) {
    state.detailData[horseNum] = {
      horseNum: horseNum,
      winOdds: generateEmptyValueGraphDataWin(-30, 0),
      forecastGraphData: {},
      winRatio: {},
    };
  }
  const data = state.detailData[horseNum];

  // map second horse win odds
  data.winOdds = winOdds?.[horseNum] ? winOdds[horseNum] : data.winOdds;

  for (const firstNum of Object.keys(horses || {})) {
    // initialize all combination of horses data
    if (firstNum === horseNum) {
      continue;
    }
    if (!data.forecastGraphData[firstNum]) {
      data.forecastGraphData[firstNum] = generateEmptyValueGraphDataForecast(
        -30,
        0
      );
    }
    if (winOdds?.[horseNum]?.[30].pv && winOdds?.[firstNum]?.[30].pv) {
      data.winRatio[firstNum] =
        winOdds[firstNum][30].pv / winOdds[horseNum][30].pv;
      data.winRatio[firstNum] = data.winRatio[firstNum].toFixed(
        data.winRatio[firstNum] >= 100 ? 1 : 2
      );
    }
    const forecast = forecastOdds?.[firstNum]?.[horseNum];
    const forecastReverse = forecastOdds?.[horseNum]?.[firstNum];
    const quinella =
      quinellaOdds?.[firstNum]?.[horseNum] ||
      quinellaOdds?.[horseNum]?.[firstNum];
    const forecastNorm = forecastOddsNorm?.[firstNum]?.[horseNum];
    const forecastReverseNorm = forecastOddsNorm?.[horseNum]?.[firstNum];
    const quinellaNorm =
      quinellaOddsNorm?.[firstNum]?.[horseNum] ||
      quinellaOdds?.[horseNum]?.[firstNum];

    for (let i = 0; i <= 30; i++) {
      if (forecast?.[i]) {
        data.forecastGraphData[firstNum][i].forecastNormal = forecast[i];
      }
      if (forecastReverse?.[i]) {
        data.forecastGraphData[firstNum][i].forecastReverse =
          forecastReverse[i];
      }
      if (quinella?.[i]) {
        data.forecastGraphData[firstNum][i].quinella =
          quinella[i] * 2 >= 999 ? 999 : quinella[i] * 2;
      }
      if (forecastNorm?.[i]) {
        data.forecastGraphData[firstNum][i].forecastNormalNorm =
          forecastNorm[i];
      }
      if (forecastReverseNorm?.[i]) {
        data.forecastGraphData[firstNum][i].forecastReverseNorm =
          forecastReverseNorm[i];
      }
      if (quinellaNorm?.[i]) {
        data.forecastGraphData[firstNum][i].quinellaNorm =
          quinellaNorm[i] * 2 >= 999 ? 999 : quinellaNorm[i] * 2;
      }
    }
  }
};
