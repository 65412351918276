import { useTranslation } from "react-i18next";
import WinChart from "./WinChart";

export const WinOddsColumn = () => {
  const { t } = useTranslation();

  // column that shows all the WINS ODDS of the race
  return {
    title: "",
    align: "center",
    children: [
      {
        title: t("race::winShortHeader"),
        align: "center",
        key: "secondOdds",
        width: "1.5%",
        sorter: (a, b) => a.winOddsLatest - b.winOddsLatest,
        render: (_) => {
          if (_?.fQStatisticsRow != null) {
            return <></>;
          }
          return <div>{_.winOddsLatest}</div>;
        },
      },
    ],
  };
};
