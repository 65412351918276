import Icon from "@ant-design/icons";
import firstRankIcon from "../../../assets/media/img/first_rank_icon_alt.png";
import secondRankIcon from "../../../assets/media/img/second_rank_icon_alt.png";
import thirdRankIcon from "../../../assets/media/img/third_rank_icon_alt.png";
import fourthRankIcon from "../../../assets/media/img/fourth_rank_icon_alt.png";

const HorseData = ({ data, raceDetailData }) => {
  // Get the rank for this horse from raceDetailData if available
  const rank =
    raceDetailData && raceDetailData[data.horseNum]
      ? raceDetailData[data.horseNum].rank
      : null;

  const rankIconMapping = {
    1: firstRankIcon,
    2: secondRankIcon,
    3: thirdRankIcon,
    4: fourthRankIcon,
  };

  const getBackgroundColor = (rank) => {
    switch (rank) {
      case 1:
        return "rgb(255, 77, 77)";
      case 2:
        return "rgb(126, 255, 126)";
      case 3:
        return "rgb(146, 146, 255)";
      case 4:
        return "rgb(238, 255, 126)";
      default:
        return "";
    }
  };

  const getRankIcon = (rank) => {
    if (rankIconMapping?.[rank] == null) {
      return;
    }
    return (
      <Icon
        component={() => (
          <img
            src={rankIconMapping?.[rank]}
            alt={`Rank_[${rank}]`}
            style={{ height: "24px", width: "24px" }}
            key={rank}
          />
        )}
      />
    );
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: getBackgroundColor(rank),
      }}
    >
      {getRankIcon(rank)}
      <div>
        <div
          style={{
            fontSize: "1.1rem",
            fontWeight: "bold",
          }}
        >
          {data.horseNum}
        </div>
        <div
          style={{
            fontSize: "1.2rem",
            fontWeight: "bold",
          }}
        >
          {data.horseName}
        </div>
        <div
          style={{
            fontSize: "0.9rem",
            color: "#444",
          }}
        >
          {data.jockeyName}, {data.trainerName}
        </div>
      </div>
    </div>
  );
};

export default HorseData;
