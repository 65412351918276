import { createSlice } from "@reduxjs/toolkit";
import { initialData } from "./common";
import { forecastProcessing } from "./handling";
import { forecastProcessingWS } from "./handlingWS";

const forecastSlice = createSlice({
  name: "forecast",
  initialState: initialData(),
  reducers: {
    setForecastData: (state, data) => {
      forecastProcessing(state, data);
    },
    setForecastDataWS: (state, data) => {
      forecastProcessingWS(state, data);
    },
    removeForecastData: (state) => {
      // reset all data
      state.winOdds = {};
      state.detailData = {};
      state.additionalData = {
        fetchWin: true,
        fetchForecast: true,
        fetchQuinella: true,
        fetchForecastNorm: true,
        fetchQuinellaNorm: true,
        showRealCurve: true,
        showNormCurve: true,
        horseLowestWinOdds: null,
        horseSecondLowestWinOdds: null,
        fQStatistics: {},
        fQSignal: {},
        quinellaOddsSignal: {},
      };
      state.updateTsRaw = null;
      state.raceTime = null;
    },
    setCurveDisplay: (state, data) => {
      state.additionalData.showRealCurve = data.payload.showRealCurve;
      state.additionalData.showNormCurve = data.payload.showNormCurve;
    },
  },
});

export const {
  setForecastData,
  setForecastDataWS,
  removeForecastData,
  setCurveDisplay,
} = forecastSlice.actions;

export default forecastSlice.reducer;
