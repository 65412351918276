import { useTranslation } from "react-i18next";
import HorseData from "./HorseDataColumn";

export const SecondHorseColumn = (raceDetailData) => {
  const { t } = useTranslation();

  // column that shows all the HORSE NUM of the race
  return {
    title: () => <div></div>,
    align: "center",
    children: [
      {
        title: t("race::horseHeader"),
        align: "center",
        key: "horseNum",
        width: "6%",
        sorter: (a, b) => a.horseNum - b.horseNum,
        render: (_) => {
          if (_?.fQStatisticsRow != null) {
            return <></>;
          }
          return <HorseData data={_} raceDetailData={raceDetailData} />;
        },
      },
    ],
  };
};
