import * as Helper from "../../utils/helper";
import {
  generateEmptyValueGraphDataForecast,
  generateEmptyValueGraphDataWin,
} from "./common";

export const forecastProcessingWS = (state, data) => {
  const forecastOdds = data.payload.forecastOddsAll;
  const quinellaOdds = data.payload.quinellaOddsAll;
  const forecastOddsNorm = data.payload.forecastOddsNorm;
  const quinellaOddsNorm = data.payload.quinellaOddsNorm;
  const winOdds = data.payload.winOddsAll;
  const winOddsLatest = data.payload.winOdds;
  const qwOddsAll = data.payload.qw;
  const horses = data.payload.race?.horses;
  const quinellaOddsSignal = data.payload.quinellaOddsSignal;

  const fQStatistics = data.payload.fQStatistics;
  const fQSignal = data.payload.fQSignal;

  horses &&
    (state.additionalData.totalNumOfHorses = Math.max(
      ...horses.map((horse) => horse.horseNum)
    ));

  state.updateTsRaw = data.payload.pool?.updateTs;
  state.raceTime = data.payload.race?.raceTime;

  // mapping each horse with win odds and all graph data
  winOddsHandling(
    state,
    state.additionalData.totalNumOfHorses,
    winOdds,
    qwOddsAll
  );
  for (let i = 1; i <= state.additionalData.totalNumOfHorses; i++) {
    forecastHandling(
      state,
      state.additionalData.totalNumOfHorses,
      i,
      state.winOdds,
      forecastOdds,
      quinellaOdds,
      forecastOddsNorm,
      quinellaOddsNorm,
      winOddsLatest,
      horses,
      quinellaOddsSignal
    );
  }

  fQStatistics && (state.additionalData.fQStatistics = fQStatistics);
  fQSignal && (state.additionalData.fQSignal = fQSignal);
  quinellaOddsSignal &&
    (state.additionalData.quinellaOddsSignal = quinellaOddsSignal);
  state.updateTS = Helper.currentTimeIn24HoursFormat();
};

const winOddsHandling = (state, totalNumOfHorses, winOdds, qwOddsAll) => {
  const horseWinOdds = [];
  for (let i = 1; i <= totalNumOfHorses; i++) {
    // if no win odds data, initialize
    if (!state.winOdds[i]) {
      state.winOdds[i] = generateEmptyValueGraphDataWin(-30, 0);
    }

    winOdds?.[i] &&
      horseWinOdds.push({
        horseNum: i,
        winOdds: winOdds[i].filter((x) => x != null).slice(-1)[0],
      }) &&
      winOdds[i].slice(30).forEach((data, index) => {
        state.winOdds[i][index].pv = data;
      });
    qwOddsAll?.[i] &&
      qwOddsAll[i].forEach((data, index) => {
        if (state.winOdds[i][index]) {
          state.winOdds[i][index].qw = data;
        }
      });
  }
  if (horseWinOdds.length >= 2) {
    horseWinOdds.sort((a, b) => a.winOdds - b.winOdds);
    state.additionalData.horseLowestWinOdds = horseWinOdds[0].horseNum;
    state.additionalData.horseSecondLowestWinOdds = horseWinOdds[1].horseNum;
  }
};

const forecastHandling = (
  state,
  totalNumOfHorses,
  horseNum,
  winOdds,
  forecastOdds,
  quinellaOdds,
  forecastOddsNorm,
  quinellaOddsNorm,
  winOddsLatest,
  horsesData,
  quinellaOddsSignal
) => {
  // initialize, if no that horse
  if (state.detailData[horseNum] == null) {
    state.detailData[horseNum] = {
      horseName: "",
      jockeyName: "",
      trainerName: "",
      horseNum: horseNum,
      winOdds: generateEmptyValueGraphDataWin(-30, 0),
      forecastGraphData: {},
      winRatio: {},
      winOddsLatest: null,
      quinellaOddsSignal: {},
    };
  }
  const data = state.detailData[horseNum];

  // map second horse win odds
  data.winOdds = winOdds?.[horseNum] ? winOdds[horseNum] : data.winOdds;

  // map horse Name and jockey name and trainer name
  const horseData = horsesData?.find((horse) => horse.horseNum === horseNum);
  if (horseData) {
    data.horseName = horseData.horseName || horseData.horseCode;
    data.jockeyName = horseData.jockeyName || horseData.jockeyCode;
    data.trainerName = horseData.trainerName || horseData.trainerCode;
  }

  for (let i = 1; i <= totalNumOfHorses; i++) {
    // initialize all combination of horses data
    if (i === horseNum) {
      continue;
    }
    if (!data.forecastGraphData[i]) {
      data.forecastGraphData[i] = generateEmptyValueGraphDataForecast(-30, 0);
    }
    if (winOdds?.[horseNum]?.[30].pv && winOdds?.[i]?.[30].pv) {
      data.winRatio[i] = winOdds[i][30].pv / winOdds[horseNum][30].pv;
      data.winRatio[i] = data.winRatio[i].toFixed(
        data.winRatio[i] >= 100 ? 1 : 2
      );
    }
    const forecast = forecastOdds?.[i]?.[horseNum];
    const forecastReverse = forecastOdds?.[horseNum]?.[i];
    const quinella =
      quinellaOdds?.[i]?.[horseNum] || quinellaOdds?.[horseNum]?.[i];
    const forecastNorm = forecastOddsNorm?.[i]?.[horseNum];
    const forecastReverseNorm = forecastOddsNorm?.[horseNum]?.[i];
    const quinellaNorm =
      quinellaOddsNorm?.[i]?.[horseNum] || quinellaOdds?.[horseNum]?.[i];
    if (winOddsLatest?.[horseNum]) {
      data.winOddsLatest = winOddsLatest[horseNum]?.latest?.win || -1;
    }
    if (quinellaOddsSignal?.[i]?.[horseNum]) {
      data.quinellaOddsSignal[i] = quinellaOddsSignal[i][horseNum];
    }

    for (let time = 0; time <= 30; time++) {
      if (forecast?.[time]) {
        data.forecastGraphData[i][time].forecastNormal = forecast[time];
      }
      if (forecastReverse?.[time]) {
        data.forecastGraphData[i][time].forecastReverse = forecastReverse[time];
      }
      if (quinella?.[time]) {
        data.forecastGraphData[i][time].quinella =
          quinella[time] * 2 >= 999 ? 999 : quinella[time] * 2;
      }
      if (forecastNorm?.[time]) {
        data.forecastGraphData[i][time].forecastNormalNorm = forecastNorm[time];
      }
      if (forecastReverseNorm?.[time]) {
        data.forecastGraphData[i][time].forecastReverseNorm =
          forecastReverseNorm[time];
      }
      if (quinellaNorm?.[time]) {
        data.forecastGraphData[i][time].quinellaNorm =
          quinellaNorm[time] * 2 >= 999 ? 999 : quinellaNorm[time] * 2;
      }
    }
  }
};
