import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import WinChart from "./WinChart";

export const WinTrendColumn = () => {
  const { t } = useTranslation();

  // Get win data at the top level
  const winData = useSelector((state) => state.win.detailData);

  // Column that shows win trend charts for each horse
  return {
    title: "",
    align: "center",
    children: [
      {
        title: t("race::winTrendHeader") || "Win Trend",
        align: "center",
        key: "winTrend",
        width: "2.5%",
        render: (_) => {
          if (_?.fQStatisticsRow != null) {
            return <></>;
          }

          // Access the win data for this specific horse
          const horseWinData = winData?.[_.horseNum]?.graphData || [];

          return (
            <div
              style={{
                height: "100px",
                width: "100%",
                padding: "0",
                margin: "0",
              }}
            >
              {horseWinData && horseWinData.length > 0 && (
                <WinChart
                  data={horseWinData}
                  range={[-30, 0]}
                  showRange={[-20, 0]}
                />
              )}
            </div>
          );
        },
      },
    ],
  };
};
