import { useSelector } from "react-redux";

const ForecastQuinellaChart = ({
  data,
  range,
  showRange,
  reverseOdds,
  ratio,
  height,
  quinellaOddsSignalDict,
}) => {
  const { raceTime, updateTsRaw } = useSelector((state) => state.forecast);

  // Define the order of keys we want to display
  const orderedKeys = [120, 35, 20, 3];

  // Get the latest value
  const latestValue = quinellaOddsSignalDict?.["latest"]?.qOdds;
  // Get the 0 minute value
  const zeroMinValue = quinellaOddsSignalDict?.["0"]?.qOdds;

  // Helper function to check if a value exists and is not empty
  const displayValue = (value) => {
    return value !== undefined && value !== null && value !== "" ? value : "--";
  };

  // Helper function to get qOdds value for a key
  const getQOddsValue = (key) => {
    if (
      quinellaOddsSignalDict &&
      quinellaOddsSignalDict[key] &&
      quinellaOddsSignalDict[key].qOdds
    ) {
      return quinellaOddsSignalDict[key].qOdds;
    }
    return "--";
  };

  // Helper function to get signal value for a key
  const getSignalValue = (key) => {
    if (
      quinellaOddsSignalDict &&
      quinellaOddsSignalDict[key] &&
      quinellaOddsSignalDict[key].signal !== undefined
    ) {
      return quinellaOddsSignalDict[key].signal;
    }
    return null;
  };

  // Helper function to get wq_signal value for a key
  const getWQSignalValue = (key) => {
    if (
      quinellaOddsSignalDict &&
      quinellaOddsSignalDict[key] &&
      quinellaOddsSignalDict[key].wqSignal !== undefined
    ) {
      return quinellaOddsSignalDict[key].wqSignal;
    }
    return null;
  };

  // Helper function to determine background color based on signal value
  const getBackgroundColor = (key) => {
    const signal = getSignalValue(key);
    if (signal === null) return "transparent";
    if (signal > 1.1) return "#FFEB3B"; // Yellow for >1.1
    else if (signal < 0.9) return "#D1C4E9"; // Purple for <0.9
  };

  const getTextBackgroundColor = (key) => {
    const wqSignal = getWQSignalValue(key);
    if (wqSignal === null) return "transparent";
    if (wqSignal > 1.2) return "#7ccc80"; // Green text for >1.2
    else if (wqSignal < 1) return "#f78981"; // Red text for <1
    return "transparent";
  };

  // Styles for the compact layout
  const containerStyle = {
    display: "flex",
    flexDirection: "row",
    border: "1px solid #ddd",
    borderRadius: "4px",
    backgroundColor: "#f9f9f9",
  };

  const timeframesRowStyle = {
    display: "flex",
    flexDirection: "column",
    borderBottom: "2px solid #ddd",
    width: "50%",
  };

  const timeframeItemStyle = {
    textAlign: "center",
    fontSize: "14px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const latestContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderTop: "1px solid #ddd",
    width: "50%",
  };

  const latestValueStyle = {
    fontSize: "18px",
    fontWeight: "bold",
  };

  return (
    <div style={containerStyle}>
      <div style={timeframesRowStyle}>
        {orderedKeys.map((key) => (
          <div
            key={key}
            style={{
              ...timeframeItemStyle,
              backgroundColor: getBackgroundColor(key),
              borderRight:
                key !== orderedKeys[orderedKeys.length - 1]
                  ? "1px solid #eee"
                  : "none",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                display: "flex",
              }}
            >
              <span style={{ backgroundColor: getTextBackgroundColor(key) }}>
                {displayValue(getQOddsValue(key))}
              </span>
            </div>
          </div>
        ))}
      </div>
      <div
        style={{
          ...latestContainerStyle,
        }}
      >
        <div
          style={{
            backgroundColor: getBackgroundColor("0"),
            width: "100%",
            textAlign: "center",
            padding: "4px 0",
          }}
        >
          <span
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              backgroundColor: getTextBackgroundColor("0"),
            }}
          >
            {displayValue(zeroMinValue)}
          </span>
        </div>
        <div
          style={{
            ...latestValueStyle,
            backgroundColor: getBackgroundColor("latest"),
            width: "100%",
            textAlign: "center",
            padding: "4px 0",
          }}
        >
          <span style={{ backgroundColor: getTextBackgroundColor("latest") }}>
            {displayValue(latestValue)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ForecastQuinellaChart;
